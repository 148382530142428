import React, { Component } from 'react';
import Link from "gatsby-link"
import {Helmet} from "react-helmet";
import DefaultLayout from '../layouts/defaultLayout'
import forte from '../img/forte_logo.png'
import _get from 'lodash/get';

//import MyGreatPlace from './my_great_place.jsx';

//import weg1 from '../img/kaart-belgie-1.jpg'
//import weg2 from '../img/kaart-belgie2.jpg'
import facebook from '../img/icons/facebook.png'
import instagram from '../img/icons/instagram.png'
import linkedin from '../img/icons/linkedin.png'

const language = {
    "nl-be": 2,
    "en-gb": 0,
    "fr-be": 1
  }

class Contact extends Component {

    constructor(props) {
        
        super(props)
        
        /*var path = this.props.location.pathname.indexOf("/")
        var lang = this.props.location.pathname.substr(path+1,5)

        //for(var i = 0;i<)

        var data ;
        console.log(this)
        for(var i = 0;i<this.props.data.allPrismicDocument.edges.length;i++){
            if(this.props.data.allPrismicDocument.edges[i].node.lang == lang){
                data = this.props.data.allPrismicDocument.edges[i];
            }
        }*/


        this.state = {
           
        }

        console.log("contact", this)

        
      }

    render() {
      var data = this.props.data.allPrismicContact.edges[0].node.data;
      console.log("contact", data)
      //var openingsuren = data.body[0].items;
      console.log("contact data", data.body[0].items)

      
      var openingsuren = data.body[0].items.map(item =>
        <div className="text">
        <span>{item.titel1.text}</span>
        <span>{item.tijd.text}</span>
        </div>
      )

      return (
        <DefaultLayout data={this.props.data.allPrismicMenu.edges[0].node.data}>                
            <Helmet>
                <title>{data.meta_title.text}</title>
                <meta name="description" content={data.meta_description.text} />
            </Helmet>
            <div style={{backgroundImage: 'url('+ data.header_image.url +')', backgroundSize:'cover', paddingBottom:'40%'}} id="productHeaderImage">
            </div>
            <div id="contactContainer">
                <div id="gegevensContainer">
                    <div className="rowContainer">
                        <div id="kaart1" className="cell">
                        <div className="locatie">
                                <div className="horizontalLine"></div>
                                <h1>Openingsuren</h1>
                                {openingsuren}
                                <br/><br/>
                                <div className="text">{data. gesloten_dagen_text.text}</div>
                            </div>
                        </div>
                        <div id="contacteeronsContainer" className="cell">
                            <div className="contacteeronsContainer">
                                <h1>Contacteer ons</h1>
                                <div id="gegevens">
                                    <div className="gegeven">
                                        Email: {data.email.text} 
                                    </div>
                                    <div className="line"></div>
                                    <div className="gegeven">
                                        Tel: {data.telefoon.text} 
                                    </div>
                                </div>
                            </div>
                        </div>
                 
                    
                        <div id="adres" className="cell">
                          <img className="weg" src={data.kaart.url}/>
                        </div>
                        <div id="kaart2" className="cell">
                        <div className="locatie">
                                <div className="horizontalLine"></div>
                                <h1>Locatie</h1>
                                <div className="text bold"><b>Forté </b></div>
                                <div className="text">{data.straat.text} </div>
                                <div className="text">{data.stad.text} </div>
                                <div className="text">{data.land.text} </div>
                                <div className="text link"><a target="_blank" href={data.routebeschrijving.text} >Routebeschrijving</a></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
            <footer>
            <div className="footerMenuContainer">
                <div className="menuLeft">
                    <div id="logoContainer">
                    <img className="logo" src={forte}/>
                    </div>
                    <div className="menuContainer">
                        <ul className="menuLinks">
                        <li><a href="/specialisaties">Specialisaties</a></li>
                          <li><a href="/over-ons">Over ons</a></li>
                          <li><a href="/contact">Contact</a></li>
                        </ul>
                    </div>
                </div>
                <div className="menuRight">
                    
                </div>
            </div>
            <div className="copyright">
            <div id="gafaslink">
                Website by
                <a href="https://www.gafas.be"> Gafas</a>
                </div>
            </div>
        </footer>
        </DefaultLayout>
      );
    }
  }
  
  export default Contact;

  export const pageQuery = graphql`
  query contact {
      allPrismicMenu {
        edges {
          node {
            id
            slugs
            lang
            data {
              logo {
                
                url
              }
              telefoonnummer {
                html
                text
              }
              contact {
                html
                text
              }
              diensten {
                html
                text
              }
              over_ons {
                html
                text
              }
              jobs {
              html
              text
              }
              afspraak {
                html
                text
              }
              body {
                ... on PrismicMenuBodyDiensten {
                  items {
                    titel {
                      html
                      text
                    }
                    link {
                      html
                      text
                    }
                  }
                }
              }
            }
          }
        }
      }
      allPrismicContact {
    edges {
      node {
        id
        data {
          meta_title {
            html
            text
          }
          meta_description {
            html
            text
          }
          titel {
            html
            text
          }
          header_image {
            
            url
          }
          kaart {
            
            url
          }
          email {
            html
            text
          }
          telefoon {
            html
            text
          }
          straat {
            html
            text
          }
          stad {
            html
            text
          }
          land {
            html
            text
          }
          routebeschrijving {
            html
            text
          }
          gesloten_dagen_text {
            html
            text
          }
          body {
            slice_type
            ... on PrismicContactBodyOpeningsuren {
              items {
                titel1 {
                  html
                  text
                }
                tijd {
                  html
                  text
                }
              }
            }
          }
        }
      }
    }
  }
    }
    
  `